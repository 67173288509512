@font-face {
    font-family: "Trivia King";
    src: url('res/fonts/triviaking.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#footer-tab-container-toolbar {
    padding-top: env(safe-area-inset-top);
}

.footer-tab-fragment-header {
    margin-top: env(safe-area-inset-top);
}

.hidescroll::-webkit-scrollbar {
    display: none;
}

.hidescroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

body {
    user-select: none;
}

a {
    color: inherit;
}

a:visited {
    color: inherit;
}
